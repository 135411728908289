<template>
  <div class="room-index">
    <v-card class="mt-3" v-if="loading">
      <v-row justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-card>

    <div v-else>
      <v-card>
        <div class="top-card-background">
          <v-img
            class="institution-logo"
            v-if="hasInstitutionLogo"
            :src="srcLogoImage"
          >
          </v-img>
          <span class="top-card-text">{{ institutionName }}</span>
          <span class="top-card-text">{{ institutionCity }}</span>
        </div>
        <BreadCrumbs v-if="hasBreadCrumbs" :breadcrumbs="breadcrumbs" />
      </v-card>

      <v-card>
        <v-card-title> Poderes </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex flex-row flex-wrap">
              <StateEntityCard
                v-bind:key="entity.id"
                v-for="entity in stateEntities"
                :entity="entity"
                @open-entity-screen="redirectToGamesOfTheEntityScreen"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <StudentsPendingRequests
      class="mt-3"
      v-if="userData && userData.Group.group_code != 1"
    />
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { baseUrl } from "@/config/config.json";
import { mapState } from "vuex";
import BreadCrumbs from "@/components/template/Breadcrumbs.vue";
import StudentsPendingRequests from "@/components/Users/StudentsPendingRequests.vue";
import StateEntityCard from "@/components/StateEntities/StateEntityCard.vue";

export default {
  components: {
    BreadCrumbs,
    StudentsPendingRequests,
    StateEntityCard,
  },
  data() {
    return {
      loading: false,
      loadingStateEntities: false,
      institutionInfo: null,
      breadcrumbs: null,
      stateEntities: [],
    };
  },
  methods: {
    async getRommInfo() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/salas/${this.roomId}`);

        this.setRoomData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setRoomData(data) {
      const cpData = { ...data };

      this.institutionInfo = cpData.institutionInfo;
      this.breadcrumbs = cpData.breadcrumbs;
    },
    async loadStateEntities() {
      try {
        this.loadingStateEntities = true;

        const response = await this.$axios.get(`/poderes`);

        this.setEntities(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingStateEntities = false;
      }
    },
    setEntities(data) {
      const cpData = [...data];

      this.stateEntities = cpData.map((e) => ({
        ...e,
      }));
    },
    redirectToGamesOfTheEntityScreen(entityId) {
      this.$router.push(`/salas/${this.roomId}/poder/${entityId}/jogos`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    roomId() {
      return this.$route.params.id;
    },
    institutionName() {
      return this.institutionInfo && this.institutionInfo.institution_name
        ? this.institutionInfo.institution_name
        : "";
    },
    institutionCity() {
      return this.institutionInfo && this.institutionInfo.city_address
        ? `${this.institutionInfo.city_address} - ${this.institutionInfo.state_address}`
        : "";
    },
    hasInstitutionLogo() {
      return this.institutionInfo && this.institutionInfo.logo ? true : false;
    },
    hasBreadCrumbs() {
      return this.breadcrumbs ? true : false;
    },
    srcLogoImage() {
      if (!this.institutionInfo || !this.institutionInfo.logo) return "/#";

      return `${baseUrl}/uploads/institutions/${this.institutionInfo.logo}`;
    },
    isAdmin() {
      return this.userData.Group.group_code === 1 ? false : true;
    },
  },
  watch: {
    roomId(value) {
      if (!value) return;

      this.getRommInfo();
    },
  },
  created() {
    this.getRommInfo();
    this.loadStateEntities();
  },
};
</script>

<style scoped>
.top-card-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #0e0d0d, #3f3f3f);
  min-height: 150px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.institution-logo {
  max-width: 12%;
}

.top-card-text {
  color: #ffffff;
}
</style>
