<template>
  <v-card class="mb-2">
    <v-card-text>
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped>
.v-breadcrumbs {
  padding: 0 !important;
}
</style>
