<template>
  <v-card
    class="mx-auto ma-1 entity-card"
    max-width="250"
    @click="redirectToGamesOfTheEntityScreen(entity.id)"
  >
    <v-img
      class="white--text align-end entity-img"
      max-width="250"
      max-height="125"
      :src="getStateEntityImg(entity.id)"
    >
    </v-img>

    <v-card-title class="black--text">{{ entityName }}</v-card-title>
  </v-card>
</template>

<script>
import executiveImg from "@/assets/planalto.jpeg";
import legislativeImg from "@/assets/congresso-nacional.jpg";
import judiciaryImg from "@/assets/stf.jpg";

export default {
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  computed: {
    entityName() {
      return this.entity ? this.entity.name : "Carregando..."
    }
  },
  data() {
    return {
      loading: false,
      srcExecutiveImg: executiveImg,
      srcLegislativeImg: legislativeImg,
      srcJudiciaryImg: judiciaryImg,
    };
  },
  methods: {
    redirectToGamesOfTheEntityScreen(entityId) {
      this.$emit("open-entity-screen", entityId);
    },
    getStateEntityImg(entityId) {
      if (entityId == 1) return this.srcExecutiveImg;
      else if (entityId == 2) return this.srcLegislativeImg;
      else if (entityId == 3) return this.srcJudiciaryImg;
    },
  },
};
</script>

<style scoped>
.entity-card:hover {
  cursor: pointer;
}

.entity-img:hover {
  opacity: 0.9;
}
</style>
