<template>
  <v-card>
    <v-toolbar elevation="2" flat>
      <v-card-subtitle>
        <strong>Solicitações pendentes de alunos</strong>
      </v-card-subtitle>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="users"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="mr-2"
            title="Aceitar"
            @click="accept(item.UserId)"
            color="success"
            icon
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            class="mr-2"
            title="Recusar"
            @click="decline(item.UserId)"
            color="error"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  computed: {
    roomId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      loading: false,
      successDialog: false,
      headers: [
        { text: "Nome", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Ações", value: "actions" },
      ],
      users: [],
    };
  },
  methods: {
    async loadPendingRequests() {
      try {
        this.loading = true;

        const response = await this.$axios.get(
          `/admin/solicitacoes/salas/${this.roomId}`
        );

        this.setUsers(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setUsers(data) {
      const cpData = { ...data };

      this.users = cpData.requests.map((u) => {
        return { ...u, fullName: `${u.firstName} ${u.lastName}` };
      });
    },
    async accept(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Confirmação",
          `Aceitar esta solicitação?`
        );

        if (!confirm) return;

        this.loading = true;

        await this.$axios.put(
          `/admin/solicitacoes/salas/${this.roomId}/aluno/aceitar`,
          { UserId: id }
        );

        this.deleteRequestFromTable(id);
        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async decline(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente recusar esta solicitação?`
        );

        if (!confirm) return;

        this.loading = true;

        await this.$axios.put(
          `/admin/solicitacoes/salas/${this.roomId}/aluno/rejeitar`,
          { UserId: id }
        );

        this.deleteRequestFromTable(id);
        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteRequestFromTable(id) {
      const index = this.users.findIndex((p) => p.id === id);

      this.users.splice(index, 1);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadPendingRequests();
  },
};
</script>

<style></style>
